import { PagingData } from 'src/app/core/models/api-response.model';
import { TruckAux } from './../../Trucks/models/trucks.model';
export interface Permit{
    companyId?: number
    permitType: PermitTypeEnum
    startDate?: string
    expirationDate?: string | null
    accountNumber: string
    state?: string
    licenseDocumentId: number | null
    id: number
    permitTrucks: PagingData<TruckAux>
    licenseYear?: number
}

export interface PermitTrucks{
    id: number
    assetTruckId: number
    permitTruckLicenseNumber: string
}

export enum PermitTypeEnum {
    IFTA = 'IFTA',
    NY = 'NY',
    KYU = 'KYU',
    NM = 'NM',
    OR = 'OR',
}

export interface OtherPermit{
    id: number
    permitType: PermitTypeEnum
    accountNumber: string
    licenseDocumentId: number | null
    licenseYear: number
}

export interface OtherPermitBody{
    id: number,
    permits: OtherPermit[]
}